import React from "react";

const ScrollNews = () => {
  return (
    <div className="scrolling__news__wrapper">
      <div className="label">Announcement</div>
      <div className="text__wrapper">
        <p>
          Admission started for skill development Courses, Paramedical Courses,
          Arts and Science, Management Courses(Regular & Distance Eduacation)
        </p>
      </div>
    </div>
  );
};

export default ScrollNews;

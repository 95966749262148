import React from "react";
import { Main } from "./Main";
import { HashRouter, Route, Switch } from "react-router-dom";
import { AppFooter } from "./layout/AppFooter";
import { Aboutus } from "./component/Aboutus";
import { ContactUs } from "./component/ContactUs";
import { PageNotFound } from "./component/PageNotFound";
import { Gallery } from "./component/Gallery";
import { Paramedical } from "./component/Paramedical";

import { UgCourses } from "./component/UgCourses";
import { PgCourses } from "./component/PgCourses";

// New Header
import NewHeader from "./layout/NewHeader";
import { PgDiploma } from "./component/PgDiploma";
import { Computer } from "./component/Computer";
import ScrollToTop from "./ScrollToTop";
import { Photos } from "./component/Photos";

export const App = () => {
  return (
    <HashRouter>
      <ScrollToTop />
      <NewHeader />
      <Switch>
        <Route path="/" component={Main} exact />
        <Route path="/aboutus" component={Aboutus} exact />
        <Route path="/contactus" component={ContactUs} exact />
        <Route path="/photos" component={Photos} exact />
        <Route path="/paramedicalcourses" component={Paramedical} exact />
        <Route path="/ugcourses" component={UgCourses} exact />
        <Route path="/pgcourses" component={PgCourses} exact />
        <Route path="/pgdiploma" component={PgDiploma} exact />
        <Route path="/computercourse" component={Computer} exact />
        <Route component={PageNotFound} exact />
      </Switch>
      <AppFooter />
    </HashRouter>
  );
};

export default App;

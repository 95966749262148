import React from "react";

export const PageNotFound = () => {
  return (
    <div>
      <section className="error-section">
        <div className="auto-container">
          <div className="content-box">
            <figure className="error-image">
              <img src="images/icons/error.png" alt="" />
            </figure>
            <h2>Page not found</h2>
            <div className="text">Please try one of the following pages:</div>
            <a href="/" className="theme-btn btn-style-one">
              <span className="btn-title">Home Page</span>
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

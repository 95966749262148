import React from "react";
import "./testimonials.css";
export const Testimonials = () => {
  return (
    <div>
      <div id="content-slider">
        <div className="wrap-slider">
          <h1 className="main-title">Quotes</h1>
          <div className="slider" style={{ width: '65%' }}>
            <div className="inset">
              <figure>
                <img src="images\18.jpg" alt="" id="i-2" className="f" />
              </figure>

              <figure>
                <img src="images\19.jpg" alt="" id="i-3" className="f" />
              </figure>

              <figure>
                <img src="images\20.jpg" alt="" id="i-4" className="f" />
              </figure>
              <figure>
                <img src="images\17.jpg" alt="" id="i-4" className="f" />
              </figure>
              <figure>
                <img src="images\20.jpg" alt="" id="i-5" className="f" />
              </figure>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

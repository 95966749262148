import React from "react";

export const PgCourses = () => {
  return (
    <div>
      <section
        className="page-title"
        style={{ backgroundImage: "url(images/background/banner.webp)" }}
      >
        <div className="auto-container">
          <div className="title-outer">
            <h1 style={{ color: "white" }}>PG Courses</h1>
            <ul className="page-breadcrumb">
              <li>
                <a href="/" style={{ color: "white" }}>
                  Home
                </a>
              </li>
              <li style={{ color: "white" }}>PG courses</li>
            </ul>
          </div>
        </div>
      </section>

      <section className="blog-section blog-three-col">
        <div className="auto-container">
          <div className="row">
            <div className="news-block col-lg-4 col-md-6 col-sm-12 wow fadeInUp">
              <div className="inner-box">
                <div className="image-box">
                  <figure className="image">
                    <a>
                      <img src="images/resource/news-8.webp" alt="" />
                    </a>
                  </figure>
                </div>
                <div className="lower-content">
                  <h4>
                    <a>MA - ENGLISH LITERATURE</a>
                  </h4>
                  <div className="text">
                    Eligibility : BA English Literature/ BA Literature or any
                    graduation course with part II English at UG level.
                    <br />
                    Course Duration : 2 Year
                  </div>
                </div>
              </div>
            </div>

            <div className="news-block col-lg-4 col-md-6 col-sm-12 wow fadeInUp">
              <div className="inner-box">
                <div className="image-box">
                  <figure className="image">
                    <a>
                      <img src="images/resource/news-9.webp" alt="" />
                    </a>
                  </figure>
                </div>
                <div className="lower-content">
                  <h4>
                    <a>M COM</a>
                  </h4>
                  <div className="text">
                    Eligibility : B.Com / B.Com (CA) / B.Com (E-Com) / BBM / BBM
                    (CA) / BBA / BCS / BCS (CA) / B.Com (CS) / B.Com (CS) with
                    CA / B.Com (Co-operation) / B.Com (Co-operation) with CA and
                    Bachelors degree in Bank Management
                    <br />
                    Course Duration : 2 Year
                  </div>
                </div>
              </div>
            </div>

            <div className="news-block col-lg-4 col-md-6 col-sm-12 wow fadeInUp">
              <div className="inner-box">
                <div className="image-box">
                  <figure className="image">
                    <a>
                      <img src="images/resource/news-10.webp" alt="" />
                    </a>
                  </figure>
                </div>
                <div className="lower-content">
                  <h4>
                    <a>
                      MCA
                      <br />
                    </a>
                  </h4>
                  <div className="text">
                    Eligibility : BCA / BSC Computer Science / Computer
                    Technology / Information Technology or any degree with
                    mathematics at plus two level or atleast one paper in
                    mathematics or statistics at degree level.
                    <br /> Course Duration : 3 Year
                  </div>
                </div>
              </div>
            </div>

            <div className="news-block col-lg-4 col-md-6 col-sm-12 wow fadeInUp">
              <div className="inner-box">
                <div className="image-box">
                  <figure className="image">
                    <a>
                      <img src="images/resource/news-11.webp" alt="" />
                    </a>
                  </figure>
                </div>
                <div className="lower-content">
                  <h4>
                    <a>MSW</a>
                  </h4>
                  <div className="text">
                    Eligibility : Any UG degree <br />
                    Course Duration : 2 Year
                  </div>
                </div>
              </div>
            </div>

            <div className="news-block col-lg-4 col-md-6 col-sm-12 wow fadeInUp">
              <div className="inner-box">
                <div className="image-box">
                  <figure className="image">
                    <a>
                      <img src="images/resource/news-12.webp" alt="" />
                    </a>
                  </figure>
                </div>
                <div className="lower-content">
                  <h4>
                    <a>MLISC</a>
                  </h4>
                  <div className="text">
                    Master of Library & Information Science Eligibility : BLISc
                    <br />
                    Course Duration : 2 Year
                  </div>
                </div>
              </div>
            </div>

            <div className="news-block col-lg-4 col-md-6 col-sm-12 wow fadeInUp">
              <div className="inner-box">
                <div className="image-box">
                  <figure className="image">
                    <a>
                      <img src="images/resource/news-13.webp" alt="" />
                    </a>
                  </figure>
                </div>
                <div className="lower-content">
                  <h4>
                    <a>MBA</a>
                  </h4>
                  <div className="text">
                    (General, Hospital Management, Banking and Finance, Shipping
                    and Logistics Management) Eligibility : A pass in any degree
                    Course
                    <br />
                    Course Duration : 2 Year
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

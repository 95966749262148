import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import TopLogo from "../assets/top-header.png";
import Branding from "../assets/branding.png";

const NewHeader = () => {
  const location = useLocation();

  return (
    <header className="app__header">
      <div className="header-top-two">
        <div className="auto-container">
          <div className="top-right">
            <div className="top__logo">
              <img src={TopLogo} alt="" />
            </div>
          </div>
          <div className="top-left">
            <ul className="contact-list">
              <li>
                <i className="flaticon-hospital-1"></i>
                College Jn, <br />
                Pathanamthitta{" "}
              </li>
              <li>
                <i className="flaticon-back-in-time"></i>Monday - Froday 9.00 -
                5.00. <br />
                Saturday 10.00 - 3.00.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <nav class="navbar navbar-expand-lg">
        <div className="container">
          <a class="navbar-brand" href="#">
            <img src={Branding} alt="" />
          </a>
          <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="menu-toggler-icon"></span>
            <span class="menu-toggler-icon"></span>
            <span class="menu-toggler-icon"></span>
          </button>

          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav mr-auto">
              <li class="nav-item active">
                <a class="nav-link" href="/">
                  Home
                </a>
              </li>
              <li class="nav-item">
                <Link class="nav-link" to="/aboutus">
                  About Us
                </Link>
              </li>
              <li class="nav-item dropdown">
                <a
                  class="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Affiliations
                </a>
                <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                  <a
                    class="dropdown-item"
                    href="http://graminskill.com/"
                    target="_blank"
                  >
                    GSDM
                  </a>
                  <a
                    class="dropdown-item"
                    href="http://www.whrde.edu.in/"
                    target="_blank"
                  >
                    WHRDE
                  </a>
                  <a
                    class="dropdown-item"
                    href="https://exams.bharatsevaksamaj.org/main/states/"
                    target="_blank"
                  >
                    BSS
                  </a>
                </div>
              </li>
              <li class="nav-item dropdown">
                <a
                  class="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Courses
                </a>
                <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                  <Link class="dropdown-item" to="/paramedicalcourses">
                    PARAMADICAL COURSES
                  </Link>
                  <Link class="dropdown-item" to="/pgdiploma">
                    PG DIPLOMA
                  </Link>
                  <Link class="dropdown-item" to="/computercourse">
                    COMPUTER COURSES
                  </Link>
                  <Link class="dropdown-item" to="/ugcourses">
                    UG COURSES
                  </Link>
                  <Link class="dropdown-item" to="/pgcourses">
                    PG COURSES
                  </Link>
                </div>
              </li>
              <li class="nav-item">
                <Link class="nav-link" to="/photos">
                  Gallery
                </Link>
              </li>
              <li class="nav-item">
                <Link class="nav-link" to="/contactus">
                  Contact Us
                </Link>
              </li>
            </ul>
            {location.pathname === "/" && (
              <ul class="navbar-nav ml-auto">
                <ScrollLink
                  exact
                  to="appointment__form"
                  spy={true}
                  smooth={true}
                  duration={500}
                  className="nav-link btn__appointment"
                >
                  APPOINTMENT
                </ScrollLink>
              </ul>
            )}
          </div>
        </div>
      </nav>
    </header>
  );
};

export default NewHeader;

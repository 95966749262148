import React from 'react';

const styles = {
  carousel: {
    maxHight: '800px',
  },
  carouselItem: {
    maxHight: '800px',
  },
  carouselIMG: {
    // height: '100%',
  },
};

export const Photos = () => {
  return (
    <div
      id='carouselExampleIndicators'
      className='carousel slide'
      data-ride='carousel'
      style={styles.carousel}
    >
      {/* <ol className='carousel-indicators'>
          <li
            data-target='#carouselExampleIndicators'
            data-slide-to='0'
            className='active'
          ></li>
          <li data-target='#carouselExampleIndicators' data-slide-to='1'></li>
          <li data-target='#carouselExampleIndicators' data-slide-to='2'></li>
          <li data-target='#carouselExampleIndicators' data-slide-to='3'></li>
          <li data-target='#carouselExampleIndicators' data-slide-to='4'></li>
          <li data-target='#carouselExampleIndicators' data-slide-to='5'></li>
          <li data-target='#carouselExampleIndicators' data-slide-to='6'></li>
        </ol> */}
      <div className='carousel-inner' style={styles.carouselItem}>
        <div className='carousel-item active' style={styles.carouselItem}>
          <img
            className='d-block w-100'
            src='images/gallery/gallery1.jpeg'
            alt='First slide'
            style={styles.carouselIMG}
          />
        </div>
        <div className='carousel-item' style={styles.carouselItem}>
          <img
            className='d-block w-100'
            src='images/gallery/gallery2.jpeg'
            alt='Second slide'
            style={styles.carouselIMG}
          />
        </div>
        <div className='carousel-item' style={styles.carouselItem}>
          <img
            className='d-block w-100'
            src='images/gallery/pic.jpeg'
            alt='Third slide'
            style={styles.carouselIMG}
          />
        </div>
        <div className='carousel-item' style={styles.carouselItem}>
          <img
            className='d-block w-100'
            src='images/gallery/gallery2.jpeg'
            alt='First slide'
            style={styles.carouselIMG}
          />
        </div>
        <div className='carousel-item' style={styles.carouselItem}>
          <img
            className='d-block w-100'
            src='images/gallery/gallery1.jpeg'
            alt='Second slide'
            style={styles.carouselIMG}
          />
        </div>
        <div className='carousel-item' style={styles.carouselItem}>
          <img
            className='d-block w-100'
            src='images/gallery/pic.jpeg'
            alt='Third slide'
            style={styles.carouselIMG}
          />
        </div>
      </div>
      <a
        className='carousel-control-prev'
        href='#carouselExampleIndicators'
        role='button'
        data-slide='prev'
      >
        <span className='carousel-control-prev-icon' aria-hidden='true'></span>
        <span className='sr-only'>Previous</span>
      </a>
      <a
        className='carousel-control-next'
        href='#carouselExampleIndicators'
        role='button'
        data-slide='next'
      >
        <span className='carousel-control-next-icon' aria-hidden='true'></span>
        <span className='sr-only'>Next</span>
      </a>
    </div>
  );
};

{
  /* <ul className='gallery'>
        <input
          type='radio'
          name='radio-btn'
          id='img-1'
          checked
          style={{ paddingTop: '100px' }}
        />
        <li className='gall-container'>
          <div className='gall'>
            <img src='images/gallery/gallery1.jpeg' alt='' />
          </div>
          <div className='nav'>
            <label for='img-6' className='prev'>
              &#x2039;
            </label>
            <label for='img-2' className='next'>
              &#x203a;
            </label>
          </div>
        </li>

        <input type='radio' name='radio-btn' id='img-2' />
        <li className='gall-container'>
          <div className='gall'>
            <img src='images/gallery/gallery2.jpeg' alt='' />
          </div>
          <div className='nav'>
            <label for='img-1' className='prev'>
              &#x2039;
            </label>
            <label for='img-3' className='next'>
              &#x203a;
            </label>
          </div>
        </li>

        <input type='radio' name='radio-btn' id='img-3' />
        <li className='gall-container'>
          <div className='gall'>
            <img src='images/gallery/pic.jpeg' alt='' />
          </div>
          <div className='nav'>
            <label for='img-2' className='prev'>
              &#x2039;
            </label>
            <label for='img-4' className='next'>
              &#x203a;
            </label>
          </div>
        </li>

        <input type='radio' name='radio-btn' id='img-4' />
        <li className='gall-container'>
          <div className='gall'>
            <img src='images/gallery/gallery1.jpeg' alt='' />
          </div>
          <div className='nav'>
            <label for='img-3' className='prev'>
              &#x2039;
            </label>
            <label for='img-5' className='next'>
              &#x203a;
            </label>
          </div>
        </li>

        <input type='radio' name='radio-btn' id='img-5' />
        <li className='gall-container'>
          <div className='gall'>
            <img src='images/gallery/gallery1.jpeg' alt='' />
          </div>
          <div className='nav'>
            <label for='img-4' className='prev'>
              &#x2039;
            </label>
            <label for='img-6' className='next'>
              &#x203a;
            </label>
          </div>
        </li>

        <input type='radio' name='radio-btn' id='img-6' />
        <li className='gall-container'>
          <div className='gall'>
            <img src='images/gallery/gallery1.jpeg' alt='' />
          </div>
          <div className='nav'>
            <label for='img-5' className='prev'>
              &#x2039;
            </label>
            <label for='img-1' className='next'>
              &#x203a;
            </label>
          </div>
        </li>

        <li className='nav-dots'>
          <label for='img-1' className='nav-dot' id='img-dot-1'></label>
          <label for='img-2' className='nav-dot' id='img-dot-2'></label>
          <label for='img-3' className='nav-dot' id='img-dot-3'></label>
          <label for='img-4' className='nav-dot' id='img-dot-4'></label>
          <label for='img-5' className='nav-dot' id='img-dot-5'></label>
          <label for='img-6' className='nav-dot' id='img-dot-6'></label>
        </li>
      </ul>
      <br /> */
}

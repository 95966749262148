import React from "react";

export const UgCourses = () => {
  return (
    <div>
      <section
        className="page-title"
        style={{ backgroundImage: "url(images/background/banner.webp)" }}
      >
        <div className="auto-container">
          <div className="title-outer">
            <h1 style={{ color: "white" }}>UG Courses</h1>
            <ul className="page-breadcrumb">
              <li>
                <a href="/" style={{ color: "white" }}>
                  Home
                </a>
              </li>
              <li style={{ color: "white" }}>UG courses</li>
            </ul>
          </div>
        </div>
      </section>

      <section className="blog-section blog-three-col">
        <div className="auto-container">
          <div className="row">
            <div className="news-block col-lg-4 col-md-6 col-sm-12 wow fadeInUp">
              <div className="inner-box">
                <div className="image-box">
                  <figure className="image">
                    <a>
                      <img src="images/resource/news-8.webp" alt="" />
                    </a>
                  </figure>
                </div>
                <div className="lower-content">
                  <h4>
                    <a>BA - ENGLISH</a>
                  </h4>
                  <div className="text">
                    Eligibility : HSC Passed<br></br>
                    Course Duration : 3 Year
                  </div>
                </div>
              </div>
            </div>

            <div className="news-block col-lg-4 col-md-6 col-sm-12 wow fadeInUp">
              <div className="inner-box">
                <div className="image-box">
                  <figure className="image">
                    <a>
                      <img src="images/resource/news-10.webp" alt="" />
                    </a>
                  </figure>
                </div>
                <div className="lower-content">
                  <h4>
                    <a>BCA</a>
                  </h4>
                  <div className="text">
                    Eligibility : HSC Passed
                    <br />
                    Course Duration : 3 Year
                  </div>
                </div>
              </div>
            </div>

            <div className="news-block col-lg-4 col-md-6 col-sm-12 wow fadeInUp">
              <div className="inner-box">
                <div className="image-box">
                  <figure className="image">
                    <a>
                      <img src="images/resource/news-14.webp" alt="" />
                    </a>
                  </figure>
                </div>
                <div className="lower-content">
                  <h4>
                    <a>BCOM</a>
                  </h4>
                  <div className="text">
                    Eligibility : HSC Passed
                    <br />
                    Course Duration : 3 Year
                  </div>
                </div>
              </div>
            </div>

            <div className="news-block col-lg-4 col-md-6 col-sm-12 wow fadeInUp">
              <div className="inner-box">
                <div className="image-box">
                  <figure className="image">
                    <a>
                      <img src="images/resource/news-15.webp" alt="" />
                    </a>
                  </figure>
                </div>
                <div className="lower-content">
                  <h4>
                    <a>BSC (ALL SUBJECTS)</a>
                  </h4>
                  <div className="text">
                    Eligibility : HSC Passed
                    <br />
                    Course Duration : 3 Year
                  </div>
                </div>
              </div>
            </div>

            <div className="news-block col-lg-4 col-md-6 col-sm-12 wow fadeInUp">
              <div className="inner-box">
                <div className="image-box">
                  <figure className="image">
                    <a>
                      <img src="images/resource/news-13.webp" alt="" />
                    </a>
                  </figure>
                </div>
                <div className="lower-content">
                  <h4>
                    <a>BBA</a>
                  </h4>
                  <div className="text">
                    Eligibility : HSC Passed
                    <br />
                    Course Duration : 3 Year
                  </div>
                </div>
              </div>
            </div>

            <div className="news-block col-lg-4 col-md-6 col-sm-12 wow fadeInUp">
              <div className="inner-box">
                <div className="image-box">
                  <figure className="image">
                    <a>
                      <img src="images/resource/news-11.webp" alt="" />
                    </a>
                  </figure>
                </div>
                <div className="lower-content">
                  <h4>
                    <a>BSW</a>
                  </h4>
                  <div className="text">
                    Eligibility : HSC Passed
                    <br /> Course Duration : 3 Year
                  </div>
                </div>
              </div>
            </div>
            <div className="news-block col-lg-4 col-md-6 col-sm-12 wow fadeInUp">
              <div className="inner-box">
                <div className="image-box">
                  <figure className="image">
                    <a>
                      <img src="images/resource/news-12.webp" alt="" />
                    </a>
                  </figure>
                </div>
                <div className="lower-content">
                  <h4>
                    <a>BLISC</a>
                  </h4>
                  <div className="text">
                    Eligibility : Any Degree
                    <br />
                    Course Duration : 1 Year
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

import React from "react";
import { NavLink } from "react-router-dom";
import { Link } from "react-scroll";
export const AppFooter = () => {
  return (
    <div>
      <footer className="main-footer">
        <div className="widgets-section">
          <div className="auto-container">
            <div className="row">
              <div className="big-column col-xl-6 col-lg-12 col-md-12 col-sm-12">
                <div className="row">
                  <div className="footer-column col-xl-7 col-lg-6 col-md-6 col-sm-12">
                    <div className="footer-widget about-widget">
                      <div className="logo">
                        <a href="index-2.html">
                          <img src="images/branding.png" alt="" />
                        </a>
                      </div>
                      <div className="text">
                        <p>
                          Career Point College of Technology and Management
                          Studies is an authorized information cum learning
                          supporting centre of Bharathiar University, Madurai -
                          Kamaraj University , Bharat Sevak Samaj and
                          WHRDE-collaboration with Singhania Univesity providing
                          affordable degree, diploma and certificate programs..{" "}
                        </p>
                      </div>
                      <ul className="social-icon-three">
                        <li>
                          <a href="https://www.facebook.com/careerpointcollege/">
                            <i className="fab fa-facebook-f"></i>
                          </a>
                        </li>
                        <li>
                          <a href="https://www.instagram.com/career_point_pta/?hl=en">
                            <i class="fab fa-instagram"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fab fa-twitter"></i>
                          </a>
                        </li>
                        {/* <li>
                          <a href="#">
                            <i className="fab fa-skype"></i>
                          </a>
                        </li> */}
                        <li>
                          <a href="#">
                            <i className="fab fa-linkedin-in"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="footer-column col-xl-5 col-lg-6 col-md-6 col-sm-12">
                    <div className="footer-widget">
                      <h2 className="widget-title">Courses</h2>
                      <ul className="user-links">
                        <li>
                          <NavLink to="/paramedicalcourses">
                            Paramedical Courses
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/">Arts Science</NavLink>
                        </li>
                        <li>
                          <NavLink to="/">Commerce Study</NavLink>
                        </li>
                        <li>
                          <NavLink to="/">Management Study</NavLink>
                        </li>
                        <li>
                          <NavLink to="/computercourse">
                            Computer Courses
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="big-column col-xl-6 col-lg-12 col-md-12 col-sm-12">
                <div className="row">
                  <div className="footer-column col-lg-6 col-md-6 col-sm-12">
                    <div className="footer-widget recent-posts">
                      <h2 className="widget-title">Latest News</h2>

                      <div className="widget-content">
                        <div className="post">
                          <div className="thumb">
                            <img
                              src="images/resource/post-thumb-1.jpg"
                              alt=""
                            />
                          </div>
                          <h4>Paramedical Courses</h4>
                        </div>

                        <div className="post">
                          <div className="thumb">
                            <img
                              src="images/resource/post-thumb-2.jpg"
                              alt=""
                            />
                          </div>
                          <h4>Computer Courses</h4>
                        </div>

                        <div className="post">
                          <div className="thumb">
                            <img
                              src="images/resource/post-thumb-3.png"
                              alt=""
                            />
                          </div>
                          <h4>Management Programmes</h4>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="footer-column col-lg-6 col-md-6 col-sm-12">
                    <div className="footer-widget contact-widget">
                      <h2 className="widget-title">Contact Us</h2>

                      <div className="widget-content">
                        <ul className="contact-list">
                          <li>
                            <span className="icon flaticon-placeholder"></span>
                            <div className="text" style={{ fontSize: "13px" }}>
                              Near Catholicate College,Main Gate College
                              Jn.Pathanamthitta
                            </div>
                          </li>

                          <li>
                            <span className="icon flaticon-call-1"></span>
                            <div className="text">
                              Mon to sat : 08:30 - 05:00
                            </div>
                            <a href="tel:+91 7511148484">
                              <strong>+91 7511148484</strong>
                            </a>
                          </li>

                          <li>
                            <span className="icon flaticon-email"></span>
                            <div className="text">
                              Do you have a Question?
                              <br />
                              <a
                                href="https://mail.google.com/mail/?view=cm&fs=1&to=careerpointctms@gmail.com&su=SUBJECT&body=BODY"
                                target="_blank"
                              >
                                <strong>careerpointctms@gmail.com</strong>
                              </a>
                            </div>
                          </li>

                          <li>
                            <span className="icon flaticon-back-in-time"></span>
                            <div className="text">
                              Monday - Friday 9.00 - 05.00
                              <br />
                              <strong> Saturday 9.00 - 05.00</strong>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-bottom">
          <div className="auto-container">
            <div className="inner-container clearfix">
              <div className="footer-nav">
                <ul className="clearfix">
                  <li>
                    <NavLink to="/aboutus">AboutUs</NavLink>
                  </li>
                  <li>
                    <NavLink to="/contactus">Contact</NavLink>
                  </li>
                  <li>
                    <NavLink to="/gallery">gallery</NavLink>
                  </li>
                </ul>
              </div>

              <div className="copyright-text">
                <p>
                  © 2020 careerpointeducation.in. All Right Reserved By{" "}
                  <a href="https://forcodetech.com/"> Forcodetech</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

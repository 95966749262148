import React from "react";

export const Paramedical = () => {
  return (
    <div>
      <section
        className="page-title"
        style={{ backgroundImage: "url(images/background/banner.webp)" }}
      >
        <div className="auto-container">
          <div className="title-outer">
            <h1 style={{ color: "white" }}>Paramedical Courses</h1>
            <ul className="page-breadcrumb">
              <li>
                <a href="/" style={{ color: "white" }}>
                  Home
                </a>
              </li>
              <li style={{ color: "white" }}>Paramedical courses</li>
            </ul>
          </div>
        </div>
      </section>

      <section className="blog-section blog-three-col">
        <div className="auto-container">
          <div className="row">
            <div className="news-block col-lg-4 col-md-6 col-sm-12 wow fadeInUp">
              <div className="inner-box">
                <div className="image-box">
                  <figure className="image">
                    <a>
                      <img src="images/resource/news-1.webp" alt="" />
                    </a>
                  </figure>
                </div>
                <div className="lower-content">
                  <h4>
                    <a>
                      DIPLOMA IN RADIOLOGY AND IMAGING TECHNOLOGY (DRIT)
                      <br />
                    </a>
                  </h4>
                  <div className="text">
                    Eligibility : SSLC/+2 Passed<br></br>Course Duration :2 Year
                  </div>
                </div>
              </div>
            </div>

            <div className="news-block col-lg-4 col-md-6 col-sm-12 wow fadeInUp">
              <div className="inner-box">
                <div className="image-box">
                  <figure className="image">
                    <a>
                      <img src="images/resource/lab-1.webp" alt="" />
                    </a>
                  </figure>
                </div>
                <div className="lower-content">
                  <h4>
                    <a>DIPLOMA IN MEDICAL LABORATORY TECHNOLOGY (DMLT)</a>
                  </h4>
                  <div className="text">
                    Eligibility : SSLC/+2 Passed<br></br>
                    Course Duration :2 Year
                  </div>
                </div>
              </div>
            </div>

            <div className="news-block col-lg-4 col-md-6 col-sm-12 wow fadeInUp">
              <div className="inner-box">
                <div className="image-box">
                  <figure className="image">
                    <a>
                      <img src="images/resource/car-1.jpg" alt="" />
                    </a>
                  </figure>
                </div>
                <div className="lower-content">
                  <h4>
                    <a>DIPLOMA IN CARDIOLOGY TECHNOLOGY (DCT)</a>
                  </h4>
                  <div className="text">
                    Eligibility : SSLC/+2 Passed<br></br>
                    Course Duration :2 Year
                  </div>
                </div>
              </div>
            </div>

            <div className="news-block col-lg-4 col-md-6 col-sm-12 wow fadeInUp">
              <div className="inner-box">
                <div className="image-box">
                  <figure className="image">
                    <a>
                      <img src="images/resource/news-1.jpg" alt="" />
                    </a>
                  </figure>
                </div>
                <div className="lower-content">
                  <h4>
                    <a>
                      DIPLOMA IN AYURVEDA NURSING AND PANCHAKARMA THERAPY
                      (DANPT)
                    </a>
                  </h4>
                  <div className="text">
                    Eligibility : SSLC/+2 Passed,<br></br>
                    Course Duration : 2 Year
                  </div>
                </div>
              </div>
            </div>

            <div className="news-block col-lg-4 col-md-6 col-sm-12 wow fadeInUp">
              <div className="inner-box">
                <div className="image-box">
                  <figure className="image">
                    <a>
                      <img src="images/resource/dia-1.jpg" alt="" />
                    </a>
                  </figure>
                </div>
                <div className="lower-content">
                  <h4>
                    <a>DIPLOMA IN DIALYSIS TECHNOLOGY</a>
                  </h4>
                  <div className="text">
                    Eligibility : SSLC/+2 Passed<br></br>
                    Course Duration : 2 Year
                  </div>
                </div>
              </div>
            </div>

            <div className="news-block col-lg-4 col-md-6 col-sm-12 wow fadeInUp">
              <div className="inner-box">
                <div className="image-box">
                  <figure className="image">
                    <a>
                      <img src="images/resource/opt-1.jpg" alt="" />
                    </a>
                  </figure>
                </div>
                <div className="lower-content">
                  <h4>
                    <a>DIPLOMA IN OPTOMETRY</a>
                  </h4>
                  <div className="text">
                    Eligibility : SSLC/+2 Passed<br></br>
                    Course Duration : 2 Year
                  </div>
                </div>
              </div>
            </div>
            <div className="news-block col-lg-4 col-md-6 col-sm-12 wow fadeInUp">
              <div className="inner-box">
                <div className="image-box">
                  <figure className="image">
                    <a>
                      <img src="images/resource/opt-2.jpg" alt="" />
                    </a>
                  </figure>
                </div>
                <div className="lower-content">
                  <h4>
                    <a>BACHELOR OF OPTOMETRY</a>
                  </h4>
                  <div className="text">
                    Eligibility : +2 with PCB/M <br></br>
                    Course Duration : 3 Year
                  </div>
                </div>
              </div>
            </div>
            <div className="news-block col-lg-4 col-md-6 col-sm-12 wow fadeInUp">
              <div className="inner-box">
                <div className="image-box">
                  <figure className="image">
                    <a>
                      <img src="images/resource/dia-2.jpg" alt="" />
                    </a>
                  </figure>
                </div>
                <div className="lower-content">
                  <h4>
                    <a>BACHELOR OF DIALYSIS TECHNOLOGY</a>
                  </h4>
                  <div className="text">
                    Eligibility : +2 with PCB/M<br></br>
                    Course Duration : 3 Year
                  </div>
                </div>
              </div>
            </div>
            <div className="news-block col-lg-4 col-md-6 col-sm-12 wow fadeInUp">
              <div className="inner-box">
                <div className="image-box">
                  <figure className="image">
                    <a>
                      <img src="images/resource/lab-2.jpg" alt="" />
                    </a>
                  </figure>
                </div>
                <div className="lower-content">
                  <h4>
                    <a>BACHELOR OF MEDICAL LABORATORY TECHNOLOGY (BMLT)</a>
                  </h4>
                  <div className="text">
                    Eligibility : +2 with PCB/M<br></br>Course Duration : 3 Year
                  </div>
                </div>
              </div>
            </div>
            <div className="news-block col-lg-4 col-md-6 col-sm-12 wow fadeInUp">
              <div className="inner-box">
                <div className="image-box">
                  <figure className="image">
                    <a>
                      <img src="images/resource/micro-1.jpg" alt="" />
                    </a>
                  </figure>
                </div>
                <div className="lower-content">
                  <h4>
                    <a>BACHELOR OF MEDICAL MICROBIOLOGY</a>
                  </h4>
                  <div className="text">
                    Eligibility : +2 with PCB/M <br></br>Course Duration : 3
                    Year
                  </div>
                </div>
              </div>
            </div>
            <div className="news-block col-lg-4 col-md-6 col-sm-12 wow fadeInUp">
              <div className="inner-box">
                <div className="image-box">
                  <figure className="image">
                    <a>
                      <img src="images/resource/radiology2.jpg" alt="" />
                    </a>
                  </figure>
                </div>
                <div className="lower-content">
                  <h4>
                    <a>BACHELOR OF RADIOLOGY AND IMAGING TECHNOLOGY</a>
                  </h4>
                  <div className="text">
                    Eligibility : +2 with PCB/M <br></br>Course Duration : 3
                    Year
                  </div>
                </div>
              </div>
            </div>
            <div className="news-block col-lg-4 col-md-6 col-sm-12 wow fadeInUp">
              <div className="inner-box">
                <div className="image-box">
                  <figure className="image">
                    <a>
                      <img src="images/resource/mas-1.jpg" alt="" />
                    </a>
                  </figure>
                </div>
                <div className="lower-content">
                  <h4>
                    <a>MASTER OF MEDICAL LABORATORY TECHNOLOGY (MSC.MLT)</a>
                  </h4>
                  <div className="text">
                    Eligibility : BSC in Biological Science / BSc. MLT<br></br>
                    Course Duration : 2 Year
                  </div>
                </div>
              </div>
            </div>
            <div className="news-block col-lg-4 col-md-6 col-sm-12 wow fadeInUp">
              <div className="inner-box">
                <div className="image-box">
                  <figure className="image">
                    <a>
                      <img src="images/resource/med-1.jpg" alt="" />
                    </a>
                  </figure>
                </div>
                <div className="lower-content">
                  <h4>
                    <a>MASTER OF MEDICAL MICROBIOLOGY</a>
                  </h4>
                  <div className="text">
                    Eligibility : BSC in Microbiology/Lifescience<br></br>Course
                    Duration : 2 Year
                  </div>
                </div>
              </div>
            </div>
            <div className="news-block col-lg-4 col-md-6 col-sm-12 wow fadeInUp">
              <div className="inner-box">
                <div className="image-box">
                  <figure className="image">
                    <a>
                      <img src="images/resource/radiology1.jpg" alt="" />
                    </a>
                  </figure>
                </div>
                <div className="lower-content">
                  <h4>
                    <a>MASTER OF RADIOLOGY AND IMAGING TECHNOLOGY</a>
                  </h4>
                  <div className="text">
                    Eligibility : BSC in Radiology and Imaging Technology
                    <br></br>Course Duration : 2 Year
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

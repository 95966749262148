import React from "react";

export const PgDiploma = () => {
  return (
    <div>
      <section
        className="page-title"
        style={{ backgroundImage: "url(images/background/banner.webp)" }}
      >
        <div className="auto-container">
          <div className="title-outer">
            <h1 style={{ color: "white" }}>PG DIPLOMA</h1>
            <ul className="page-breadcrumb">
              <li>
                <a href="/" style={{ color: "white" }}>
                  Home
                </a>
              </li>
              <li style={{ color: "white" }}>PG DIPLOMA</li>
            </ul>
          </div>
        </div>
      </section>

      <section className="blog-section blog-three-col">
        <div className="auto-container">
          <div className="row">
            <div className="news-block col-lg-4 col-md-6 col-sm-12 wow fadeInUp">
              <div className="inner-box">
                <div className="image-box">
                  <figure className="image">
                    <a>
                      <img src="images/resource/com-1.webp" alt="" />
                    </a>
                  </figure>
                </div>
                <div className="lower-content">
                  <h4>
                    <a>PG DIPLOMA IN COMPUTER APPLICATION</a>
                  </h4>
                  <div className="text">
                    Eligibility : A pass in any degree Course<br></br>
                    Course Duration : 1 Years
                  </div>
                </div>
              </div>
            </div>

            <div className="news-block col-lg-4 col-md-6 col-sm-12 wow fadeInUp">
              <div className="inner-box">
                <div className="image-box">
                  <figure className="image">
                    <a>
                      <img src="images/resource/bus-1.jpg" alt="" />
                    </a>
                  </figure>
                </div>
                <div className="lower-content">
                  <h4>
                    <a>PG DIPLOMA IN INTERNATIONAL BUSINESS</a>
                  </h4>
                  <div className="text">
                    Eligibility : A pass in any degree Course
                    <br />
                    Course Duration : 1 Year
                  </div>
                </div>
              </div>
            </div>

            <div className="news-block col-lg-4 col-md-6 col-sm-12 wow fadeInUp">
              <div className="inner-box">
                <div className="image-box">
                  <figure className="image">
                    <a>
                      <img src="images/resource/news-3.webp" alt="" />
                    </a>
                  </figure>
                </div>
                <div className="lower-content">
                  <h4>
                    <a>PG DIPLOMA IN MEDICAL INSTRUMENTATION</a>
                  </h4>
                  <div className="text">
                    Eligibility : A pass in any degree Course
                    <br />
                    Course Duration : 1 Year
                  </div>
                </div>
              </div>
            </div>
            <div className="news-block col-lg-4 col-md-6 col-sm-12 wow fadeInUp">
              <div className="inner-box">
                <div className="image-box">
                  <figure className="image">
                    <a>
                      <img src="images/resource/bio-1.jpg" alt="" />
                    </a>
                  </figure>
                </div>
                <div className="lower-content">
                  <h4>
                    <a>PG DIPLOMA IN BIO-INFORMATICS</a>
                  </h4>
                  <div className="text">
                    Eligibility : A Bachelor degree in any one of the following
                    as major subject : Agriculture Applied Science, Animal
                    Science, Bio-Chemistry, Biology, Bio-Technology, Botony,
                    Plant Biology and Plant Bio-Technology, Chemistry, Computer
                    Science, Computer Application, IT, Electronics,
                    Microbiology, Pharmacy, Physics, Statistics, Vetenary
                    Science and zoology.
                    <br />
                    Course Duration : 1 Year
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

import React from "react";
import emailjs from "emailjs-com";
import swal from "sweetalert";
export const ContactUs = () => {
  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        "gmail",
        "template_ehn7bz7",
        e.target,
        "user_NBLoFS5lx1CDDSRUlzvVL"
      )
      .then(
        (result) => {
          swal({
            title: "Done!",
            text: "We will get back to you shortly!",
            icon: "success",
            timer: 3000,
            button: false,
          });
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  }
  return (
    <div>
      <section
        class="page-title"
        style={{ backgroundImage: "url(images/background/banner.webp)" }}
      >
        <div class="auto-container">
          <div class="title-outer">
            <h1 style={{ color: "white" }}>Contact Us</h1>
            <ul class="page-breadcrumb">
              <li>
                <a href="/" style={{ color: "white" }}>
                  Home
                </a>
              </li>
              <li style={{ color: "white" }}>Contact</li>
            </ul>
          </div>
        </div>
      </section>
      <section className="contact-section" id="contact">
        <div className="small-container">
          <div className="sec-title text-center">
            <span className="sub-title">Contact Now</span>
            <h2>Write us a Message !</h2>
            <span className="divider"></span>
          </div>

          <div className="contact-box">
            <div className="row">
              <div className="contact-info-block col-lg-4 col-md-6 col-sm-12">
                <div className="inner">
                  <span className="icon flaticon-worldwide"></span>
                  <h4>
                    <strong>Address</strong>
                  </h4>
                  <p style={{ fontSize: "12px" }}>
                    Near Catholicate College,Main Gate College Jn.Pathanamthitta
                    <br />
                  </p>
                </div>
              </div>

              <div className="contact-info-block col-lg-4 col-md-6 col-sm-12">
                <div className="inner">
                  <span className="icon flaticon-phone"></span>
                  <h4>
                    <strong>Phone</strong>
                  </h4>
                  <p>
                    <a href="tel:+91 7511148484">+91 7511148484</a>
                  </p>
                  <p>
                    <a href="tel:+91 8547492484">+91 8547492484</a>
                  </p>
                </div>
              </div>

              <div className="contact-info-block col-lg-4 col-md-6 col-sm-12">
                <div className="inner">
                  <span className="icon flaticon-email"></span>
                  <h4>
                    <strong>Email</strong>
                  </h4>
                  <p>
                    <a
                      href="https://mail.google.com/mail/?view=cm&fs=1&to=careerpointctms@gmail.com&su=SUBJECT&body=BODY"
                      target="_blank"
                    >
                      careerpointctms@gmail.com
                    </a>
                  </p>
                  {/* <p>
                    <a href="mailto:support@example.com">support@example.com</a>
                  </p> */}
                </div>
              </div>
            </div>
          </div>

          <div className="form-box">
            <div className="contact-form">
              <form onSubmit={sendEmail}>
                <div className="row">
                  <div className="form-group col-lg-12">
                    <div className="response"></div>
                  </div>

                  <div className="col-lg-6 col-md-12">
                    <div className="form-group">
                      <input
                        type="text"
                        name="username"
                        className="username"
                        placeholder="Full Name *"
                        required
                      />
                    </div>

                    <div className="form-group">
                      <input
                        type="email"
                        name="email"
                        className="email"
                        placeholder="Email Address *"
                        required
                      />
                    </div>

                    <div className="form-group">
                      <input
                        type="text"
                        name="phone"
                        className="username"
                        placeholder="Your Phone *"
                        required
                      />
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-12">
                    <div className="form-group">
                      <textarea
                        name="message"
                        className="message"
                        placeholder="Message"
                      ></textarea>
                    </div>
                  </div>

                  <div className="form-group col-lg-12 text-center pt-3">
                    <input
                      name="submit"
                      type="submit"
                      id="submit"
                      className="btn btn-success"
                      value="SEND MESSAGE"
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

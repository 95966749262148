import React from "react";
import { NavLink } from "react-router-dom";
import { Link } from "react-scroll";

import GSDMLogo from "../assets/gsdm_icon.ico";
import WHRDELogo from "../assets/whrde_icon.ico";
import NSDCLOGO from "../assets/nsdc.ico";
import GRDlogo from "../assets/gradu.ico";

export const TopSection = () => {
  return (
    <div>
      <section className="appointment-section appointment">
        <div className="image-layer"></div>
        <div className="auto-container">
          <div className="row">
            <div className="content-column col-lg-6 col-md-12 col-sm-12"></div>
            <div className="image-column col-lg-6 col-md-12 col-sm-12"></div>
          </div>

          <div className="fun-fact-section">
            <div className="row">
              <div className="counter-column col-lg-3 col-md-6 col-sm-12 wow fadeInUp">
                <div className="count-box">
                  <a href="http://graminskill.com/" target="_blank">
                    <div className="icon-box">
                      <img src={GSDMLogo} alt="" />
                    </div>
                    <h4 className="counter-title">GSDM</h4>
                  </a>
                </div>
              </div>

              <div
                className="counter-column col-lg-3 col-md-6 col-sm-12 wow fadeInUp"
                data-wow-delay="400ms"
              >
                <div className="count-box">
                  <NavLink to="/computercourse">
                    <div className="icon-box">
                      <img src={NSDCLOGO} alt="" />
                    </div>
                    <h4 className="counter-title">Computer Courses</h4>
                  </NavLink>{" "}
                </div>
              </div>

              <div
                className="counter-column col-lg-3 col-md-6 col-sm-12 wow fadeInUp"
                data-wow-delay="800ms"
              >
                <div className="count-box">
                  <NavLink to="/pgdiploma">
                    <div className="icon-box">
                      <img src={GRDlogo} alt="" />
                    </div>
                    <h4 className="counter-title">PG Diploma</h4>
                  </NavLink>
                </div>
              </div>

              <div
                className="counter-column col-lg-3 col-md-6 col-sm-12 wow fadeInUp"
                data-wow-delay="1200ms"
              >
                <div className="count-box">
                  <a href="http://www.whrde.edu.in/" target="_blank">
                    <div className="icon-box">
                      <img className="img__whrde" src={WHRDELogo} alt="" />
                    </div>
                    <h4 className="counter-title">WHRDE</h4>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

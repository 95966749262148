import React from "react";
import { GetInTouch } from "./component/GetInTouch";
import { Highlights } from "./component/Highlights";
import { Testimonials } from "./component/Testimonials";

import { TopSection } from "./component/TopSection";
import { AppBanner } from "./layout/AppBanner";
import NewBanner from "./layout/NewBanner";
import ScrollNews from "./component/ScrollNews";

export const Main = () => {
  return (
    <>
      <ScrollNews />
      <NewBanner />
      <TopSection />
      <Highlights />
      <GetInTouch />
      <Testimonials />
    </>
  );
};

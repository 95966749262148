import React from "react";

export const Aboutus = () => {
  return (
    <div>
      <section
        class="page-title"
        style={{ backgroundImage: "url(images/background/banner.webp)" }}
      >
        <div class="auto-container">
          <div class="title-outer">
            <h1 style={{ color: "white" }}>About Us</h1>
            <ul class="page-breadcrumb">
              <li>
                <a href="/" style={{ color: "white" }}>
                  Home
                </a>
              </li>
              <li style={{ color: "white" }}>About</li>
            </ul>
          </div>
        </div>
      </section>
      <section className="about-section">
        <div className="auto-container">
          <div className="row">
            <div className="content-column col-lg-6 col-md-12 col-sm-12 order-2">
              <div className="inner-column">
                <div className="sec-title">
                  <span className="sub-title">Career Point</span>
                  <h2>Welcome to Career Point</h2>
                  <span className="divider"></span>
                  <p>
                    Career Point College is an authorised training center for
                    NSDC and Admission <br />
                    Co-ordination and learning Supporting Center of UGC Approved
                    universities. For Regular and Distance education Programmes
                    and Collaburated with NGO's to Government Of India
                  </p>
                </div>
              </div>
            </div>

            <div className="images-column col-lg-6 col-md-12 col-sm-12">
              <div className="inner-column">
                <div className="video-link">
                  <a
                    href="https://www.youtube.com/watch?v=6qu_1FSb_H0"
                    className="play-btn lightbox-image"
                    data-fancybox="images"
                  >
                    <span className="flaticon-play-button-1"></span>
                  </a>
                </div>
                <figure className="image-1">
                  <img src="images/resource/image-1.webp" alt="" />
                </figure>
                <figure className="image-2">
                  <img src="images/resource/image-2.webp" alt="" />
                </figure>
                <figure className="image-3">
                  <span className="hex"></span>
                  <img src="images/resource/image-3.webp" alt="" />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

import React from "react";

import OurHighlightIMG from "../assets/our-highlight.webp";

export const Highlights = () => {
  return (
    <div>
      <div class="call-to-action-two">
        <div class="auto-container">
          <div class="row">
            <div class="content-column col-lg-6 col-md-12 col-sm-12">
              <div class="inner-column">
                <div class="sec-title">
                  <span class="sub-title"></span>
                  <h2>OUR HIGHLIGHTS</h2>
                  <span class="divider"></span>
                </div>

                <div class="feature-block-seven">
                  <div class="inner-box">
                    <span class="count">*</span>

                    <div class="text">
                      Students get the skills to turn their passions into
                      careers.
                    </div>
                  </div>
                </div>

                <div class="feature-block-seven">
                  <div class="inner-box">
                    <span class="count">*</span>

                    <div class="text">Free Basic Foreign Language Classes.</div>
                  </div>
                </div>
                <div class="feature-block-seven">
                  <div class="inner-box">
                    <span class="count">*</span>

                    <div class="text">
                      Well equiped Digital classes and computer lab
                    </div>
                  </div>
                </div>
                <div class="feature-block-seven">
                  <div class="inner-box">
                    <span class="count">*</span>

                    <div class="text">100% placement assistance</div>
                  </div>
                </div>
                <div class="feature-block-seven">
                  <div class="inner-box">
                    <span class="count">*</span>

                    <div class="text">
                      Free computer education and spoken English classes
                    </div>
                  </div>
                </div>
                <div class="feature-block-seven">
                  <div class="inner-box">
                    <span class="count">*</span>

                    <div class="text">Academic library</div>
                  </div>
                </div>
                <div class="feature-block-seven">
                  <div class="inner-box">
                    <span class="count">*</span>

                    <div class="text">Updated Study Materials</div>
                  </div>
                </div>
                <div class="feature-block-seven">
                  <div class="inner-box">
                    <span class="count">*</span>

                    <div class="text">Motivation and Counseling</div>
                  </div>
                </div>
                <div class="feature-block-seven">
                  <div class="inner-box">
                    <span class="count">*</span>

                    <div class="text">
                      Part-time and distant mode scheme available for selected
                      courses
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="image-column col-lg-6 col-md-12 col-sm-12">
              <figure class="image">
                <img src={OurHighlightIMG} alt="" />
              </figure>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

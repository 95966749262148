import React from "react";
import emailjs from "emailjs-com";
import swal from "sweetalert";
export const GetInTouch = () => {
  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        "gmail",
        "template_jvooebi",
        e.target,
        "user_NBLoFS5lx1CDDSRUlzvVL"
      )
      .then(
        (result) => {
          swal({
            title: "Done!",
            text: "We will get back to you shortly!",
            icon: "success",
            timer: 3000,
            button: false,
          });
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  }
  return (
    <div>
      <section className="appointment-form-section">
        <div className="auto-container">
          <div className="row">
            <div className="content-column col-lg-6 col-md-12 col-sm-12">
              <div className="inner-column">
                <a
                  href="https://www.youtube.com/watch?v=6qu_1FSb_H0"
                  className="play-now"
                  data-fancybox="gallery"
                  data-caption=""
                >
                  <i
                    className="icon flaticon-play-button"
                    aria-hidden="true"
                  ></i>
                  <span className="ripple"></span>
                </a>

                <div className="content">
                  <span className="title">Enquiry Now?</span>
                  <h3>
                    Just Make an Appointment <br />
                    and You’re Done!
                  </h3>
                  <div className="text">
                    Call Us Now: <strong>+91 7511-14-84-84</strong>
                  </div>
                </div>
              </div>
            </div>

            <div
              id="appointment__form"
              className="form-column col-lg-6 col-md-12 col-sm-12"
            >
              <div className="inner-column">
                <div className="appointment-form default-form">
                  <div className="sec-title">
                    <span className="sub-title">Online Appoinment</span>
                    <h2>Make An Appointment</h2>
                    <span className="divider"></span>
                  </div>

                  <form onSubmit={sendEmail}>
                    <div className="form-group">
                      <div className="response"></div>
                    </div>

                    <div className="form-group">
                      <input
                        type="text"
                        name="username"
                        placeholder="Your Name"
                      />
                    </div>

                    <div className="form-group">
                      <input
                        type="email"
                        name="email"
                        placeholder="Your Email *"
                      />
                    </div>

                    <div className="form-group">
                      <input
                        type="text"
                        name="phone"
                        placeholder="Your Phone *"
                      />
                    </div>
                    <div className="form-group">
                      <select name="qualification">
                        <option value="">Select Qualification</option>
                        <option>SSLC</option>
                        <option>Plus Two</option>
                        <option>Degree</option>
                        <option>PG</option>
                      </select>
                    </div>

                    <div className="form-group">
                      <select name="course">
                        <option value="">Select Course</option>
                        <option>Paramedical Course</option>
                        <option>PG Diploma</option>
                        <option>Computer Course</option>
                        <option>UG Course</option>
                        <option>PG Course</option>
                      </select>
                    </div>

                    <div className="form-group">
                      <textarea
                        name="address"
                        placeholder="Enter Your Address"
                      ></textarea>
                    </div>

                    <div className="form-group">
                      <input
                        name="submit"
                        type="submit"
                        id="submit"
                        className="btn btn-success"
                        value="SEND MESSAGE"
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

import React from "react";
import { Fade, Zoom } from "react-reveal";

// Images
import BannerIMG1 from "../assets/banner-img-1.jpg";
import BannerIMG2 from "../assets/banner-img-2.webp";
import BannerIMG3 from "../assets/banner-img-3.jpg";
import BannerIMG4 from "../assets/banner-img-4.jpg";
import BannerIMG5 from "../assets/banner-img-5.jpg";
import BannerIMG6 from "../assets/banner-img-6.jpg";

const NewBanner = () => {
  return (
    <div
      id="carouselExampleIndicators"
      className="banner carousel slide"
      data-ride="carousel"
    >
      <ol className="carousel-indicators">
        <li
          data-target="#carouselExampleIndicators"
          data-slide-to="0"
          className="active"
        ></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
      </ol>
      <div className="carousel-inner">
        <div className="carousel-item slider__1 active">
          <img className="d-block w-100" src={BannerIMG1} alt="First slide" />
          <div className="carousel-caption">
            {/* <p>
              <Zoom cascade left>
                The best place for education.
              </Zoom>
            </p> */}
          </div>
        </div>
        <div className="carousel-item slider__2">
          <img className="d-block w-100" src={BannerIMG2} alt="Second slide" />
          <div className="carousel-caption">
            {/* <Fade top>
              <h1>
                WELCOME <br /> TO <br /> CAREER POINT.
              </h1>
            </Fade>
            <p>
              <Zoom cascade right>
                The best place for education.
              </Zoom>
            </p> */}
          </div>
        </div>
        <div className="carousel-item slider__2">
          <img className="d-block w-100" src={BannerIMG3} alt="Second slide" />
          <div className="carousel-caption"></div>
        </div>
        <div className="carousel-item slider__2">
          <img className="d-block w-100" src={BannerIMG4} alt="Second slide" />
          <div className="carousel-caption"></div>
        </div>
        <div className="carousel-item slider__2">
          <img className="d-block w-100" src={BannerIMG5} alt="Second slide" />
          <div className="carousel-caption"></div>
        </div>
        <div className="carousel-item slider__2">
          <img className="d-block w-100" src={BannerIMG6} alt="Second slide" />
          <div className="carousel-caption"></div>
        </div>
      </div>

      <a
        className="carousel-control-prev"
        href="#carouselExampleIndicators"
        role="button"
        data-slide="prev"
      >
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="sr-only">Previous</span>
      </a>
      <a
        className="carousel-control-next"
        href="#carouselExampleIndicators"
        role="button"
        data-slide="next"
      >
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="sr-only">Next</span>
      </a>
    </div>
  );
};

export default NewBanner;
